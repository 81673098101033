<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg" />
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-row class="mb-4">
        <b-col>
          <b-form-group
            :description="
              $t('calendarModule.slotForm.descriptions.fromDateBookingVacation')
            "
            :state="!$v.fromDate.$error"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotForm.labels.fromDate") }}
              <span class="text-danger">*</span>
            </template>

            <!-- v-if="slot._id || forUserId"-->
            <date-picker
              class="w-100"
              format="DD.MM.YYYY"
              input-class="form-control pac-target-input"
              v-model="$v.fromDate.$model"
              @change="fromDateChanged"
            ></date-picker>
            <!--<b-input v-else :value="forDateFormatted" :disabled="true" />-->
            <b-form-invalid-feedback>{{
              $t("calendarModule.slotForm.errors.date")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :description="
              $t('calendarModule.slotForm.descriptions.toDateBookingVacation')
            "
            :state="!$v.toDate.$error"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotForm.labels.toDate") }}
              <span class="text-danger">*</span>
            </template>

            <!-- v-if="slot._id || forUserId"-->
            <date-picker
              class="w-100"
              format="DD.MM.YYYY"
              input-class="form-control pac-target-input"
              v-model="$v.toDate.$model"
              @change="toDateChanged"
            ></date-picker>
            <!--<b-input v-else :value="forDateFormatted" :disabled="true" />-->
            <b-form-invalid-feedback>{{
              $t("calendarModule.slotForm.errors.date")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="inBetweenSlots.length > 0">
        <b-col>
          <p class="danger-text mb-4">
            {{
              $t("calendarModule.bookVacationForm.labels.slotsToDeleteMessage")
            }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-2 mt-2">
        <b-col>
          <b-button
            @click="() => confirmBookVacation()"
            class="btn btn-primary mr-2"
            size="xs"
          >
            {{ $t("misc.confirm") }}
          </b-button>
          <b-button
            @click="() => abortBookVacation()"
            class="btn btn-outline-primary ml-2"
            size="xs"
          >
            {{ $t("misc.abort") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="table-responsive mt-5">
            <perfect-scrollbar>
              <table id="style-3" class="table style-3  table-hover">
                <thead>
                  <tr>
                    <th>
                      {{ $t("calendarModule.slotForm.labels.date") }}
                    </th>
                    <th>
                      {{ $t("calendarModule.slotForm.labels.time") }}
                    </th>
                    <th>
                      {{ $t("calendarModule.slotForm.labels.endTime") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(slot, sIdx) in inBetweenSlots" :key="sIdx">
                    <td>
                      <span>{{ slot.date }}</span>
                    </td>
                    <td>
                      <span>{{ slot.time }}</span>
                    </td>
                    <td>
                      <span>{{ slot.endTime }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/index.css";
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";
import { convertToLocal } from "../../../utils/timezoneHelper";

export default {
  name: "BookVacationForm",
  props: {
    closeBookVacationForm: Function
  },
  components: {
    DatePicker
  },
  validations: {
    fromDate: {
      required
    },
    toDate: {
      required
    }
  },
  data() {
    return {
      cmApi: cmApi,
      loading: false,
      loadingError: false,
      currentUser: null,
      fromDate: null,
      toDate: null,
      inBetweenSlots: []
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"])
  },
  methods: {
    confirmBookVacation() {
      this.loading = true;
      this.loadingError = false;
      this.inBetweenSlots.forEach(slot => {
        cmApi.slot
          .deleteTimeSlot(this.currentUser._id, slot._id)
          .then(res => {
            this.loading = false;
            this.loadingError = false;
            this.$emit("calendarAddedOrChanged");
          })
          .catch(err => {
            this.loading = false;
            this.loadingError = true;
          });
      });
    },
    calendarChanged() {
      this.$emit("calendarAddedOrChanged");
    },
    abortBookVacation() {
      this.$emit("calendarAddedOrChanged");
    },
    fromDateChanged() {
      this.reloadSlotsInBetween();
    },
    toDateChanged() {
      this.reloadSlotsInBetween();
    },
    reloadSlotsInBetween() {
      this.inBetweenSlots = [];
      let slots = null;
      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getAllTimeSlots(this.currentUser._id)
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          const timeSlots = res.data.data;
          slots = timeSlots;
          slots.forEach(slot => {
            if (
              moment(slot.date).isBetween(
                moment(this.fromDate).startOf("day"),
                moment(this.toDate).endOf("day"),
                null,
                "[]"
              ) &&
              slot.free === true
            ) {
              const convertedUtcSlot = convertToLocal(
                slot.date,
                slot.endDate,
                slot.time,
                slot.endTime
              );
              const payload = {
                date: convertedUtcSlot.date,
                endDate: convertedUtcSlot.endDate,
                time: convertedUtcSlot.time,
                endTime: convertedUtcSlot.endTime,
                free: slot.free,
                _id: slot._id
              };
              this.inBetweenSlots.push(payload);
            }
          });
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
  },
  mounted() {
    this.reloadSlotsInBetween();
  }
};
</script>

<style scoped></style>
